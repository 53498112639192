<template>
  <div class="Industry_cont">
     <!-- 园区企业融资卡片 -->
    <el-card class="Industry">
      <div class="Industry_title">
        <h2>园区企业融资</h2>
      </div>
      <el-form :model="getCorporate_finance" ref="FormCont" label-width="95px" size="small " class="IndustryFrom">
        <el-form-item label="A轮:" prop="pre_A" :rules="[{ type: 'number', message: '必须为数字值'}]">
          <el-input type="age" @change="changeCorporate"  v-model.number="getCorporate_finance.pre_A" autocomplete="off"></el-input>
          <span> 家</span>
        </el-form-item>
        <el-form-item label="B轮:" prop="pre_B" :rules="[{ type: 'number', message: '必须为数字值'}]">
          <el-input type="age" @change="changeCorporate" v-model.number="getCorporate_finance.pre_B" autocomplete="off"></el-input>
          <span> 家</span>
        </el-form-item>
        <el-form-item label="C轮:" prop="pre_C" :rules="[{ type: 'number', message: '必须为数字值'}]">
          <el-input  type="age" @change="changeCorporate" v-model.number="getCorporate_finance.pre_C" autocomplete="off"></el-input>
          <span> 家</span>
        </el-form-item>
        <el-form-item label="新三板:" prop="new_pre" :rules="[{ type: 'number', message: '必须为数字值'}]">
          <el-input  type="age" @change="changeCorporate" v-model.number="getCorporate_finance.new_pre" autocomplete="off"></el-input>
          <span> 家</span>
        </el-form-item>
      </el-form>
    </el-card>
    <!-- 医疗健康产业图谱 -->
    <el-card class="Industry">
      <div class="Industry_title">
        <h2>医疗健康产业图谱</h2>
      </div>
      <el-form :model="medical_map" ref="FormCont_map" label-width="95px" size="small " class="healthyFrom">
        <el-form-item label="产业类型:" prop="industry_type" :rules="[{ type: 'string', required: true, message: '请输入产业类型', trigger: 'blur' }]">
          <el-input v-model.number="medical_map.industry_type" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="企业数量:" prop="company_num" :rules="[{ type: 'number', message: '必须为数字值'}]">
          <el-input type="age" v-model.number="medical_map.company_num" autocomplete="off"></el-input>
        </el-form-item>
        <el-button type="primary" @click="addHealthy">增加</el-button>
      </el-form>
      <div class="tag_cont">
        <el-tag v-for="(item, index) in tags" :key="index"  class="tagItem" closable type="info" @close="handleClose(item.id)">{{item.industry_type}} {{item.company_num}}</el-tag>
      </div>
    </el-card>

    <!-- 园区企业 -->
    <el-card class="enterprise facilities">
      <div class="enterprise_title">
        <h2>园区企业</h2>
      </div>
      <el-button type="primary" class="facilities_btn" @click="dialogVisible4 = true">新增园区企业</el-button>
      <el-table :data="joining_company" border style="width: 80%" class="facilities_table">
        <el-table-column type="index" label="序号" width="50" align="center">
          <template slot-scope="scope">
            <div v-text="(queryInfo.jc_page - 1) * 10 + scope.$index + 1"></div>
          </template>
        </el-table-column>
        <el-table-column prop="image" label="封面" align="center">
          <template slot-scope="scope">
            <el-image :src="serverAddress + scope.row.image" fit="fill"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="类型" align="center">
          <template>
            企业
          </template>
        </el-table-column>
        <el-table-column prop="name" label="企业名称" align="center"></el-table-column>
        <el-table-column prop="profile" label="企业简介" align="center"></el-table-column>
        <el-table-column prop="order_num" label="排序" align="center">
          <template slot-scope="scope">
            {{ scope.row.order_num ? scope.row.order_num : 0 }}
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间" align="center">
          <template slot-scope="scope">
            {{new Date(scope.row.created_at).getFullYear() + '-' + (new Date(scope.row.created_at).getMonth()+1) + '-' + new Date(scope.row.created_at).getDate()}}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="showEditDialog4(scope.row.id)">编辑</el-button>
            <el-button type="text"  @click="removeJoiningCompany(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @current-change="jc_page_change"
        :current-page="queryInfo.jc_page"
        :total="queryInfo.jc_total">
      </el-pagination>
    </el-card>
    <el-dialog class="attract_cont" title="新增园区企业" :visible.sync="dialogVisible4" :closeOnClickModal="false" :center="true" width="50%">
      <!-- 内容主体区 -->
      <el-form id='addFormRef4' ref="addFormRef4" :model="joining_company_data" :rules="addRules4"  label-width="100px">
        <el-form-item label="封面：" prop="image">
          <el-upload
            class="avatar-uploader"
            action=""
            accept="image/jpeg,image/gif,image/png"
            name="image"
            :on-change="onUploadChange4"
            :auto-upload="false"
            :show-file-list="false">
            <img v-if="joining_company_data.image" :src="joining_company_data.image" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="企业名称:" prop="name">
          <el-input placeholder="输入企业名称" name='name' v-model="joining_company_data.name"></el-input>
        </el-form-item>
        <el-form-item label="企业简介:" prop="profile">
          <el-input type="textarea" placeholder="输入企业简介" name='profile' v-model="joining_company_data.profile"></el-input>
        </el-form-item>
        <el-form-item label="排序:" prop="order_num">
          <el-input placeholder="输入排序号" name='order_num' v-model="joining_company_data.order_num"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible4 = false">取 消</el-button>
              <el-button type="primary" @click="addJoiningCompany">确 定</el-button>
            </span>
    </el-dialog>
    <el-dialog class="attract_cont" title="编辑园区企业" :visible.sync="dialogVisibleEdit4" :closeOnClickModal="false" :center="true" width="50%">
      <el-form id='addFormRefEdit4' :model="get_joining_company" :rules="addRules4"  label-width="100px">
        <el-form-item label="封面：" prop="image">
          <el-upload
            class="avatar-uploader"
            action=""
            accept="image/jpeg,image/gif,image/png"
            name="image"
            :on-change="onUploadChangeEdit4"
            :auto-upload="false"
            :show-file-list="false">
            <img v-if="get_joining_company.image" :src="get_joining_company.image" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="企业名称:" prop="name">
          <el-input placeholder="输入企业名称" name='name' v-model="get_joining_company.name"></el-input>
        </el-form-item>
        <el-form-item label="企业简介:" prop="profile">
          <el-input type="textarea" placeholder="输入企业简介" name='profile' v-model="get_joining_company.profile"></el-input>
        </el-form-item>
        <el-form-item label="排序:" prop="order_num">
          <el-input placeholder="输入排序号" name='order_num' v-model="get_joining_company.order_num"></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleEdit4 = false">取 消</el-button>
        <el-button type="primary" @click="editJoiningCompany">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data () {
    return {
      serverAddress: 'https://res.bjstarfish.com/',
      // 企业融资的数据
      corporate_finance: {},
      // 获取企业融资的数据
      getCorporate_finance: {},

      joining_company: [],
      dialogVisible4: false,
      dialogVisibleEdit4: false,

      // 医疗健康产业图谱的数据
      medical_map: {
        industry_type: '',
        company_num: ''
      },
      joining_company_data: {
        image: '',
        name: '',
        profile: '',
        order_num: ''
      },
      get_joining_company: {
        image: '',
        name: '',
        profile: '',
        order_num: ''
      },
      tags: [],
      tableData: [],
      queryInfo: {
        // 当前的页数
        pageNum: 1,
        // 当前每页显示多少条数据
        pageSize: 10,
        jc_page: 1,
        jc_total: 0
      },
      // 总条目数
      total: 0,
      // 添加企业代表的数据的开关
      dialogVisible: false,
      // 编辑企业代表的数据的开关
      dialogVisibleEdit: false,
      // 添加企业代表的数据
      company_representative: {
        name: '',
        number: '',
        phase: '',
        moneys: '',
        property_rights: '',
        rate: '',
        type: '',
        proportion: '',
        group_of: '',
        industry_layout: '',
        industry_evaluation: '',
        industry_impact: '',
        industry_iteration: '',
        industry_develop: '',
        company_layout: '',
        company_evaluation: '',
        company_impact: '',
        company_iteration: '',
        company_develop: ''
      },
      // 获取添加企业代表的数据
      get_company_representative: {},
      addRules4: {
        name: [
          { required: true, message: '请输入商户名称', trigger: 'blur' }
        ],
        image: [
          { required: true, message: '请选取封面', trigger: 'blur' }
        ],
        profile: [
          { required: true, message: '请输入商户简介', trigger: 'blur' }
        ]
      },
      // 验证规则
      addFormRefRules: {
        name: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        number: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        phase: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        moneys: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        property_rights: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        rate: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        proportion: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        group_of: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  async created () {
    this.getCorporate()
    this.getMedical()
    // this.getCompany()
    this.getJoiningCompanyList()
  },
  computed: {
    // 处理过分页的table数据
    AfterChangeData () {
      const start = (this.queryInfo.pageNum - 1) * this.queryInfo.pageSize
      const end = this.queryInfo.pageNum * this.queryInfo.pageSize
      return this.tableData.slice(start, end)
    }
  },
  methods: {
    // 获取园区企业融资的数据
    async getCorporate () {
      const { data: res } = await this.$http.get('corporate_finance')
      this.corporate_finance[0] = res.data
      for (const key in this.corporate_finance[0][0]) {
        this.corporate_finance[0][0][key] = Number(this.corporate_finance[0][0][key])
      }
      this.getCorporate_finance = this.corporate_finance[0][0]
    },
    async getJoiningCompanyList () {
      const { data: res } = await this.$http.get(`hsa_joining_company?limit=10&page=${this.queryInfo.jc_page}`)
      this.joining_company = res.data
      this.queryInfo.jc_page = res.pagination.page
      this.queryInfo.jc_total = res.pagination.all_count
    },
    // 修改园区企业融资的数据
    changeCorporate () {
      this.$refs.FormCont.validate(async valid => {
        if (!valid) return false
        await this.$http.post(`corporate_finance/${this.getCorporate_finance.id}`, {
          pre_A: this.getCorporate_finance.pre_A,
          pre_B: this.getCorporate_finance.pre_B,
          pre_C: this.getCorporate_finance.pre_C,
          new_pre: this.getCorporate_finance.new_pre,
          _method: 'put'
        })
      })
    },
    // 获取医疗健康产业图谱数据
    async getMedical () {
      const { data: res } = await this.$http.get('medical_map')
      this.tags = res.data
    },
    // 添加医疗健康产业图谱数据
    addHealthy () {
      this.$refs.FormCont_map.validate(async valid => {
        if (!valid) return false
        const { data: res } = await this.$http.post('medical_map', {
          industry_type: this.medical_map.industry_type,
          company_num: this.medical_map.company_num
        })
        this.getMedical()
        if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
      })
    },
    // 删除医疗健康产业图谱数据
    async handleClose (id) {
      const { data: res } = await this.$http.delete(`medical_map/${id}`)
      this.getMedical()
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
    },
    // 获取企业代表的数据
    async getCompany () {
      const { data: res } = await this.$http.get('company_representative')
      this.tableData = res.data
      this.total = this.tableData.length
    },
    // 删除企业代表的数据
    async removeInfo (id) {
      const { data: res } = await this.$http.delete(`company_representative/${id}`)
      this.getCompany()
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
    },
    // 监听 pagesize改变的事件
    handleSizeChange (newSize) {
      this.queryInfo.pageSize = newSize
    },
    // 监听 页码值 改变的事件
    handleCurrentChange (newPage) {
      this.queryInfo.pageNum = newPage
    },
    // 添加企业代表
    async addFacilities () {
      // this.$refs.companyRef.validate(async valid => {
      //   if (!valid) return false
      // })
      const { data: res } = await this.$http.post('company_representative', this.company_representative)
      if (res.msg === 'success') this.$message({ message: '新增成功', type: 'success' })
      this.dialogVisible = false
      this.getCompany()
    },
    // 编辑企业代表的按钮
    async showEditDialog (id) {
      this.dialogVisibleEdit = true
      const { data: res } = await this.$http.get(`company_representative/${id}`)
      this.getCorporate_finance = res.data
    },
    // 编辑成功
    async addFacilitiesEdit () {
      // this.$refs.getCorporateRef.validate(async valid => {
      //   if (!valid) return false
      // })
      const { data: res } = await this.$http.post(`company_representative/${this.getCorporate_finance.id}`, {
        _method: 'put',
        name: this.getCorporate_finance.name,
        number: this.getCorporate_finance.number,
        phase: this.getCorporate_finance.phase,
        moneys: this.getCorporate_finance.moneys,
        property_rights: this.getCorporate_finance.property_rights,
        new_results: this.getCorporate_finance.new_results,
        rate: this.getCorporate_finance.rate,
        type: this.getCorporate_finance.type,
        proportion: this.getCorporate_finance.proportion,
        group_of: this.getCorporate_finance.group_of,
        industry_layout: this.getCorporate_finance.industry_layout,
        industry_evaluation: this.getCorporate_finance.industry_evaluation,
        industry_impact: this.getCorporate_finance.industry_impact,
        industry_iteration: this.getCorporate_finance.industry_iteration,
        industry_develop: this.getCorporate_finance.industry_develop,
        company_layout: this.getCorporate_finance.company_layout,
        company_evaluation: this.getCorporate_finance.company_evaluation,
        company_impact: this.getCorporate_finance.company_impact,
        company_iteration: this.getCorporate_finance.company_iteration,
        company_develop: this.getCorporate_finance.company_develop
      })
      if (res.msg === 'success') this.$message({ message: '编辑成功', type: 'success' })
      this.getCompany()
      this.dialogVisibleEdit = false
    },
    onUploadChangeEdit4 (file) {
      const isIMAGE = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif')
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.get_joining_company.image = e.currentTarget.result
      }
    },
    addJoiningCompany () {
      const data = new FormData(document.getElementById('addFormRef4'))
      this.$refs.addFormRef4.validate(async valid => {
        if (!valid) return false
        const { data: res } = await this.$http.post('hsa_joining_company', data)
        this.dialogVisible4 = false
        if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
        this.getJoiningCompanyList()
      })
    },
    async editJoiningCompany () {
      const data = new FormData(document.getElementById('addFormRefEdit4'))
      data.append('_method', 'put')
      const { data: res } = await this.$http.post(`hsa_joining_company/${this.get_joining_company.id}`, data)
      this.dialogVisibleEdit4 = false
      if (res.msg === 'success') this.$message({ message: '修改成功', type: 'success' })
      this.getJoiningCompanyList()
    },
    async removeJoiningCompany (id) {
      const { data: res } = await this.$http.delete(`hsa_joining_company/${id}`)
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
      this.getJoiningCompanyList()
    },
    async showEditDialog4 (id) {
      this.dialogVisibleEdit4 = true
      const { data: res } = await this.$http.get(`hsa_joining_company/${id}`)
      this.get_joining_company = res.data
      this.get_joining_company.image = this.serverAddress + res.data.image
    },
    onUploadChange4 (file) {
      const isIMAGE = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif')
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.joining_company_data.image = e.currentTarget.result
      }
    },
    jc_page_change (newPage) {
      this.queryInfo.jc_page = newPage
      this.getJoiningCompanyList()
    }
  }
}
</script>

<style lang="less" scoped>

.facilities_btn{
  margin: 1rem;
}
.park,.enterprise{
  margin-bottom: 1rem;
  .park_title,.enterprise_title{
    position: relative;
    h2{
      padding-left: 0.7rem;
      font-size: 1.25rem;
      font-weight: bold;
    }
    ::after{
      content: '';
      position: absolute;
      left: 0;
      top: 4px;
      width: 3px;
      height: 20px;
      background-color: #000;
    }
  }
  .rowClass{
    flex-direction: column;
  }
  .el-row{
    display: flex;
    width: 100%;
  }
  .parkFrom,.enterpriseFrom{
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
    .el-form-item{
      width: 20%;
      .el-input{
        width: 75%;
      }
    }
  }
}
.save_btn{
  display: flex;
  justify-content: center;
  .el-button--primary{
    background-color: #015478;
    border-color: #015478;
    padding: 12px 60px;
  }
}

.Industry_cont{
  padding: 1rem;
  .Industry{
    margin-bottom: 1rem;
    .Industry_title{
      position: relative;
      h2{
        padding-left: 0.7rem;
        font-size: 1.25rem;
        font-weight: bold;
      }
      ::after{
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }
    .IndustryFrom{
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      padding: 1rem;
      .el-form-item{
        width: 20%;
        text-align: center;
        .el-input{
          width: 75%;
        }
      }
    }
    .healthyFrom{
     width: 50%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      .el-form-item{
        width: 30%;
        text-align: center;
        .el-input{
          width: 100%;
        }
      }
      .el-button{
        width: 10%;
        background-color: #015478;
        border-color: #015478;
        padding: 8px 20px;
        font-size: 14px;
        border-radius: 5px;
        align-self: flex-start;
      }
    }
    .tag_cont{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding-left: 7rem;
      .tagItem{
        width: 10%;
        text-align: center;
        height: 46px;
        border: none;
        line-height: 46px;
        margin-right: 1rem;
      }
    }
    .Industry_table{
      margin: 1rem;
    }
  }
  .Industry_title_btn{
    display: flex;
    align-items: center;
    .el-button{
      width: 7%;
      background-color: #015478;
      border-color: #015478;
      padding: 8px;
      font-size: 14px;
      border-radius: 5px;
      align-self: flex-start;
      margin-left: 2rem;
    }
  }
  .Industry_dialog{
    .table_cont{
      // margin: 1rem;
      width: 82%;
      display: flex;
      flex-direction: column;
      margin-left: 2rem;
      border: 1px solid #999;
      .table_cont_items{
        display: flex;
        // justify-content: space-between;
        .table_item{
          flex: 1;
          text-align: center;
          border-bottom: 1px solid #999;
          border-right: 1px solid #999;
          padding: 0.3rem;
        }
        .lastItem{
          border-right: none;
        }
        .lastItemBot{
          border-bottom: 0;
        }
      }
    }
  }
}
</style>
